
import { Action, Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AppConstants } from 'apps/middle/src/app/app.constants';
import { ActionType, newAggregationId, StoreLoader } from '@aston/foundation';
import { DebtorsService, SuperDebtorDetailService } from 'apps/middle/src/app/debtors-module/services';
import { WebSocketService } from 'apps/middle/src/app/shared-module/services';
import { IConfirmModalResult } from 'apps/middle/src/app/shared-module/models';
import { Injectable, inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EMPTY, Observable, of } from 'rxjs';
import { SuperDebtorDetailIdentityFormComponent } from 'apps/middle/src/app/debtors-module/components/super-debtor-detail-identity-form/super-debtor-detail-identity-form.component';
import { tap, switchMap, map, withLatestFrom, takeUntil, filter, catchError as rxjsCatchError } from 'rxjs/operators';
import { WsEventType } from 'apps/middle/src/app/shared-module/enums';
import { SuperDebtorTemporaryCreditLimitFormComponent } from 'apps/middle/src/app/debtors-module/components/super-debtor-temporary-credit-limit-form/super-debtor-temporary-credit-limit-form.component';
import { EllisphereStore } from '@aston/ellisphere';
import { FeatureFlagsService } from '@aston/feature-flags';
import { toObservable } from '@angular/core/rxjs-interop';

import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';
import * as DunningActionDetailStoreActions from '../../dunning-action-detail-store/actions';
import { ISuperDebtorIdentityForm, ISuperDebtorTemporaryCreditLimitFormPayload } from '../../../debtors-module/models';
import { catchWithAppError as catchError, AppStoreActions } from '../../app-store';
import { aggregate, CorrelationParams } from '../../models/correlated-actions.model';
import { SuperDebtorPageAccountingStore, SuperDebtorPageChartsStore, SuperDebtorPageStore } from '../../../debtors-module/stores/super-debtor-page';
import { observeComputedFeature } from '../../../factor-features-module/stores/factor-features';
import { DunningTimelineStore } from '../../../dunnings-module/stores/dunning-timeline.store';
import { ReferentialStore } from '../../../shared-module/stores/referential';

@Injectable({
	providedIn: 'root'
})
export class SuperDebtorPageStoreIdentityEffects {
	private ellisphereStore = inject(EllisphereStore)
	private actions$ = inject(Actions)
	private store = inject(Store)
	private ffs = inject(FeatureFlagsService)
	private superDebtorDetailService = inject(SuperDebtorDetailService)
	private modalService = inject(NgbModal)
	private ws = inject(WebSocketService)
	private debtorDetailService = inject(DebtorsService)
	private tlStore = inject(DunningTimelineStore)
	private referential = inject(ReferentialStore)
	private sdPageStore = inject(SuperDebtorPageStore)
	private sdChartStore = inject(SuperDebtorPageChartsStore)
	private sdAccountingStore = inject(SuperDebtorPageAccountingStore)

	initSuperDebtorEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitSuperDebtorPageRequest),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return [
				this.ffs.isFeatureEnabled('superDebtorPageShowExtraData') ? featureActions.LoadSuperDebtorExtradataRequest(action.id, correlationParams) : AppStoreActions.Noop(),
				this.ffs.isFeatureEnabled('superDebtorPageShowExtraData') ? featureActions.LoadSuperDebtorFreedataRequest(action.id, correlationParams) : AppStoreActions.Noop(),
				featureActions.LoadSuperDebtorContactsRequest(action.id, correlationParams),
				AppStoreActions.Execute(() => {
					this.sdPageStore.loadIdentity(action.id)
						.then(_ => this.sdAccountingStore.loadContentiousDocsCount())
						.then(_ => this.sdPageStore.loadRecoveryAdvisors())
				}),
				DunningActionDetailStoreActions.LoadNotifiablesUsersRequest({ param: action.id }),
			];
		}),
		catchError(error => of(featureActions.InitSuperDebtorPageFailure({error})))
	));

	initSuperDebtorSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitSuperDebtorPageRequest),
		// Create an aggregated action listener
		// it will observe the race of all specified observable
		// and complete when all these actions are dispatched.
		// Note that individual actions workflow still apply.
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadSuperDebtorContactsSuccess)),
			this.actions$.pipe(ofType(featureActions.InitSuperDebtorPageFailure))
		),
		map(_ => featureActions.InitSuperDebtorPageSuccess()),
		catchError(error => of(featureActions.InitSuperDebtorPageFailure({error})))
	));

	loadSuperDebtorScoring$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorScoringRequest),
		switchMap(action => this.superDebtorDetailService.getSuperDebtorScoring(action.id).pipe(
			map(entity => featureActions.LoadSuperDebtorScoringSuccess({entity, correlationParams: action.correlationParams})),
			catchError(error => of(featureActions.LoadSuperDebtorScoringFailure({error})))
		))
	));

	openSuperDebtorIdentityFormEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenSuperDebtorIdentityForm),
		withLatestFrom(
			this.sdPageStore.identityAsObservable(),
			observeComputedFeature<boolean>('isImportMenuDisabled'),
			observeComputedFeature<boolean>('isManualInvoiceEnabled'),
		),
		map(([_, superDebtorIdentity, isImportMenuDisabled, manualInvoicing]) => {
			const modalRef = this.modalService.open(SuperDebtorDetailIdentityFormComponent, {...AppConstants.DEFAULT_MODAL_OPTIONS});
			const modal = (modalRef.componentInstance as SuperDebtorDetailIdentityFormComponent);

			modal.superDebtor = superDebtorIdentity;

			modal.countries = this.referential.localizedCountries();
			modal.languages = this.referential.localizedLanguages();

			modal.isImportMenuDisabled = isImportMenuDisabled;
			modal.manualInvoicingEnabled = manualInvoicing;

			modal.loader = new StoreLoader(
				this.store.select(featureSelectors.selectSuperDebtorIdentityFormIsLoading),
				this.store.select(featureSelectors.selectSuperDebtorIdentityFormError)
			);

			modal.submitForm.pipe(
				takeUntil(modal.destroySubscriptions$)
			).subscribe((entity: ISuperDebtorIdentityForm) => {
				this.store.dispatch(featureActions.UpdateSuperDebtorIdentityFormRequest(entity));
			});
		})
	), {dispatch: false});

	updateSuperDebtorIdentityFormRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorIdentityFormRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectSuperDebtorId),
		),
		switchMap(([action, id]) => {
			return this.debtorDetailService.updateDebtorIdentity(id, action.entity).pipe(
				switchMap(_ => [
					featureActions.UpdateSuperDebtorIdentityFormSuccess({entity: action.entity}),
					AppStoreActions.Execute(() => this.sdPageStore.loadIdentity(action.entity.id)),
				]),
				catchError(error =>
					of(featureActions.UpdateSuperDebtorIdentityFormFailure({error}))
				));
		})
	));

	updateSuperDebtorIdentityFormSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorIdentityFormSuccess),
		tap(() => this.modalService.dismissAll())
	), {dispatch: false});

	confirmStopContentiousEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StopContentiousConfirm),
		map(_ => AppStoreActions.OpenConfirmationModal(
			{
				textsKey: `SuperDebtors.Contentious.Confirmations.CloseContentious.`,
				cancelBtnText: 'Actions.Cancel',
				confirmBtnText: 'Actions.Save',
				withComment: true
			},
			(result: IConfirmModalResult) => [featureActions.StopContentiousRequest({ comment: result.comment })]
		))
	));

	stopContentiousRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StopContentiousRequest),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		switchMap(([action, identity]) => this.superDebtorDetailService.stopContentious(identity.id, action.comment).pipe(
			switchMap(_ => [
				featureActions.StopContentiousSuccess(),
				AppStoreActions.Execute(() => this.sdAccountingStore.reloadAccounting()),
				featureActions.LoadSuperDebtorDunningCasesRequest(),
				DunningActionDetailStoreActions.CancelCompletionForm(),
			]),
			catchError(error => of(featureActions.StopDunningScenarioFailure({error})))
		))
	))

	// Contact success
	loadSuperDebtorContactsRequestEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorContactsRequest),
		switchMap(action => this.superDebtorDetailService.getSuperDebtorContactsForAction(action.id).pipe(
			map(superDebtorContacts => featureActions.LoadSuperDebtorContactsSuccess({
				entity: superDebtorContacts,
				correlationParams: action.correlationParams
			})),
			catchError(error => of(featureActions.LoadSuperDebtorContactsFailure({error})))
		))
	));

	loadExtradataEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorExtradataRequest),
		withLatestFrom(this.store),
		switchMap(([action]) => this.superDebtorDetailService.getExtradata(action.id).pipe(
			map(entity => featureActions.LoadSuperDebtorExtradataSuccess({ entity })),
			catchError(error => of(featureActions.LoadSuperDebtorExtradataFailure({error})))
		))
	));

	loadFreedataEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadSuperDebtorFreedataRequest),
		switchMap(action => this.superDebtorDetailService.getFreedata(action.id).pipe(
			map(entity => featureActions.LoadSuperDebtorFreedataSuccess({ entity })),
			catchError(error => of(featureActions.LoadSuperDebtorFreedataFailure({error})))
		))
	));

	updateFreedataEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorFreeDataRequest),
		switchMap(action => this.superDebtorDetailService.updateFreedata(action.entity.superDebtorId, action.entity.freedata).pipe(
			switchMap(_ => [
				featureActions.UpdateSuperDebtorFreeDataSuccess({ entity: action.entity }),
				featureActions.LoadSuperDebtorFreedataRequest(action.entity.superDebtorId)
			]),
			catchError(error => of(featureActions.UpdateSuperDebtorFreeDataFailure({error})))
		))
	));

	updateEllisphereAssignation$ = createEffect(() => toObservable(this.ellisphereStore.assignationLoaded).pipe(
		filter(Boolean),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		map(([_, identity]) => featureActions.LoadSuperDebtorFreedataRequest(identity.id))
	))

	reloadOnImport$ = createEffect(() => this.ws.ofType(WsEventType.GlobalWorkflowCompleted).pipe(
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		filter(([_, identity]) => !!identity),
		switchMap(_ => [
			AppStoreActions.Execute(() => this.tlStore.loadTimeline()),
			featureActions.LoadSuperDebtorDunningCasesRequest(),
		])
	));

	confirmInvoicingCurrency$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateInvoicingCurrencyConfirm),
		withLatestFrom(this.sdPageStore.identityAsObservable()),
		map(([action, identity]) => AppStoreActions.OpenConfirmationModal(
			{textsKey: `Debtors.Confirmations.UpdateInvoicingCurrency.`},
			featureActions.UpdateInvoicingCurrencyRequest(action.entity),
			featureActions.LoadSuperDebtorIdentitySuccess({ identity }),
		))
	));

	updateInvoicingCurrency$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateInvoicingCurrencyRequest),
		withLatestFrom(
			this.store.select(featureSelectors.selectSuperDebtorId),
			this.store.select(featureSelectors.selectPayingCenterMode)),
		switchMap(([action, id, payingCenterMode]) => this.superDebtorDetailService.updateSuperDebtorInvoicingCurrency(id, action.entity).pipe(
			switchMap(_ => [
				featureActions.InitSuperDebtorPageRequest({
					id,
					payingCenterMode,
					correlationParams: { correlationId: newAggregationId() }
				})
			]),
			catchError(error => of(featureActions.UpdateInvoicingCurrencyFailure({error})))
		))
	))

	updateSector$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorSectorRequest),
		switchMap(({entity}) => this.superDebtorDetailService.updateSuperDebtorSector(entity).pipe(
			withLatestFrom(this.sdPageStore.identityAsObservable()),
			switchMap(([_, identity]) => [
				featureActions.UpdateSuperDebtorSectorSuccess({entity}),
				AppStoreActions.Execute(() => this.sdPageStore.loadIdentity(identity.id)),
				AppStoreActions.Execute(() => this.sdChartStore.reloadAllCharts()),
			]),
			catchError(error => of(featureActions.UpdateSuperDebtorSectorFailure({error})))
		))
	))

	updateCreditLimit$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorCreditLimitRequest),
		switchMap(({entity}) => this.superDebtorDetailService.updateSuperDebtorCreditLimit(entity).pipe(
			withLatestFrom(this.sdPageStore.identityAsObservable()),
			switchMap(([_, identity]) => [
				featureActions.UpdateSuperDebtorCreditLimitSuccess({entity}),
				AppStoreActions.Execute(() => this.sdPageStore.loadIdentity(identity.id)),
				AppStoreActions.Execute(() => this.sdChartStore.reloadAllCharts()),
			]),
			catchError(error => of(featureActions.UpdateSuperDebtorCreditLimitFailure({error})))
		))
	))

	editTemporaryCreditLimitFormRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateSuperDebtorTemporaryCreditLimitRequest),
		switchMap(({entity}) => this.superDebtorDetailService.updateSuperDebtorTemporaryCreditLimit(entity).pipe(
			withLatestFrom(this.sdPageStore.identityAsObservable()),
			switchMap(([_, identity]) => {
				this.modalService.dismissAll();
				return [
					featureActions.UpdateSuperDebtorTemporaryCreditLimitSuccess({entity}),
					AppStoreActions.Execute(() => this.sdPageStore.loadIdentity(identity.id)),
					AppStoreActions.Execute(() => this.sdChartStore.reloadAllCharts()),
				]
			}),
			catchError(error => of(featureActions.UpdateSuperDebtorTemporaryCreditLimitFailure({error})))
		))
	))

	editTemporaryCreditLimitFormOpen$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.EditTemporaryCreditLimitFormOpen),
		tap(action => {
			const modal = this.modalService.open(SuperDebtorTemporaryCreditLimitFormComponent, AppConstants.DEFAULT_MODAL_OPTIONS);

			const component = (modal.componentInstance as SuperDebtorTemporaryCreditLimitFormComponent)

			if (action.temporaryCreditLimitEnd) {
				const {temporaryCreditLimit, temporaryCreditLimitEnd} = action;

				component.currentTemporaryCreditLimit = {
					temporaryCreditLimit,
					temporaryCreditLimitEnd
				};
			}

			component.resultEvent.pipe(
				rxjsCatchError(_ => {
					this.modalService.dismissAll();
					return EMPTY;
				}),
				map(event => {
					const reason = event.type;

					if (reason === ActionType.CLOSE || reason === ActionType.CANCEL) {
						this.sdPageStore.loadIdentity(action.id);
						this.modalService.dismissAll();
					}
					if (reason === ActionType.SUBMIT) {
						const entity: ISuperDebtorTemporaryCreditLimitFormPayload = {id: action.id, ...event.value};
						this.store.dispatch(featureActions.UpdateSuperDebtorTemporaryCreditLimitRequest({entity}));
					}
				}),
			).subscribe();
		})
	), { dispatch: false });
}
